import React from 'react';
import axios from 'axios';
import qs from 'qs';
import { Link } from 'gatsby';

class StreamUnsub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const { email, id } = query;

    if (!id || !email) {
      await this.setState({
        success: false,
        loading: false,
      });
      return;
    }
    try {
      await axios.post('https://api.horny.moe/unsubscribe/', {
        email: decodeURIComponent(email),
        id,
      });
      await this.setState({
        success: true,
        loading: false,
      });
    } catch (err) {
      await this.setState({
        success: false,
        loading: false,
      });
      console.log(err);
    }
  }

  render() {
    const { success, loading } = this.state;

    if (loading) {
      return (
        <div className="pt-5">
          <div className="loader" />
        </div>
      );
    }

    if (!loading && !success) {
      return (
        <div className="pt-5">
          <h3>Unable to process request.</h3>
          <p>
            To unsubscribe, please click the unsubscribe link from one of our automatic emails.
            It looks like you came to this page directly.
            If you aren&apos;t subscribed you can do so
            {' '}
            <Link to="/stream-subscribe">here</Link>
            .
          </p>
        </div>
      );
    }

    return (
      <div className="pt-5">
        <h3>Success!</h3>
        <p>
          You have been removed from our email system. This was a full deletion, we no longer
          have your email anywhere on our systems. You simply. Don&apos;t. Exist.
          If you&apos;ve changed your mind you can resubscribe
          {' '}
          <Link to="/stream-subscribe">here</Link>
          .
        </p>
      </div>
    );
  }
}

export default StreamUnsub;
