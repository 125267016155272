import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import StreamUnsub from '../components/StreamUnsub';

const StreamSignupPage = () => (
  <Layout>
    <SEO title="Stream Unsubscribe" />
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-6 lg-col-3 mx-auto">
          <StreamUnsub />
        </div>
      </div>
    </div>
  </Layout>
);

export default StreamSignupPage;
